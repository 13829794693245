import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Container, Grid } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
export default class Menu extends Component {
  render() {
    return (
      <Container style={{ paddingTop: '20px' }}>
        <Grid container
          direction="row"
          justify="space-between"
          alignItems="center" className="desktop-logo">
          <Link to={`/`}> <img src={require('../assets/logo.png')} width="100" alt="" /> </Link>
          <Link to={`/`}>   <HomeIcon style={{ fontSize: 40, color: '#000' }} /> </Link>
        </Grid>
        <div style={{ textAlign: 'center' }} className="mobile-logo">
          <Link to={`/`}> <img src={require('../assets/logo.png')} width="200" alt="" /> </Link>
        </div>
      </Container>


    )
  }
}

import React, { Component } from 'react'
import { Button, Container, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom'

export default class Error extends Component {
    render() {
        return (
            <Container>
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center" style={{ paddingTop: '15%' }}>
                    <img src={require('../assets/error.png')} width="40%" alt="" />
                </Grid>
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center" style={{ paddingTop: '30px' }}>
                    <Link to={`/`} style={{ textDecoration: 'none' }}>
                        <Button variant="contained" style={{ backgroundColor: '#000', color: '#fff' }}>Back To Home</Button>
                    </Link>
                </Grid>
            </Container>
        )
    }
}

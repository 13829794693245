import { Button, Container, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
// import Facebook from 'react-sharingbuttons/dist/buttons/Facebook'
// import Twitter from 'react-sharingbuttons/dist/buttons/Twitter'
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import '../index.css'
// import html2canvas from 'html2canvas';
// import { InlineShareButtons } from 'sharethis-reactjs';
import QuoteMenu from './QuoteMenu';


export default function Quote() {
    const { id } = useParams();
    const [singleQuote, setSingleQuote] = useState("");
    const [phrase, setPhrase] = useState(null);
    const [prevQuote, setPrevQuote] = useState(null);
    const [prevAuth, setPrevAuth] = useState(null);
    const [author, setAuthor] = useState("");
    const [auth, setAuth] = useState(null);
    const [quotes, setQuotes] = useState([]);


    useEffect(() => {
        // NewQuote();      
        getQuote();
    }, []);

    useEffect(() => {
        if (quotes === null) {
            setPhrase("Wait for a moment...")
        } else if (phrase === null) {
            setPhrase("Wait for a moment...")
        } else {
            getRandomQuote();
        }
    }, [quotes]);

    useEffect(() => {
        if (phrase === null) {
            setPhrase("Wait for a moment...")
        } else {
            transQuote();
            transAuthor();
        }
    }, [singleQuote, prevQuote]);

    // useEffect(() => {
    //     transAuthor();
    // }, [author, prevQuote]);


    // const NewQuote = () => {
    //     fetch('https://type.fit/api/quotes')
    //         .then(response => response.json())
    //         .then(res => {
    //             // console.log("old quote",res);
    //             let quote = res.map(o => o.text);
    //             const quoteArray = quote;
    //             const randomItem = quoteArray[Math.floor(Math.random() * quoteArray.length)];
    //             setSingleQuote(randomItem);
    //             const a = quote.indexOf(randomItem);
    //             let b = res[a].author;
    //             setAuthor(b);
    //         })
    //         .catch(error => {
    //             console.log("No data");
    //         })
    // }

    const getQuote = () => {
        const formData = new FormData()
        formData.append('username', process.env.REACT_APP_API_KEY_USERNAME)
        formData.append('password', process.env.REACT_APP_API_KEY_PASSWORD)
        fetch('https://worldwisdom.in/admin/api/key_generate', {
            method: "POST",
            body: formData
        })
            .then(response => response.json())
            .then(res => {
                // const key = res.key;
                // if (key !== null) {
                //     fetch(`https://worldwisdom.in/admin/api?key=${key}`, {
                //         method: "GET"
                //     })
                //         .then(response => response.json())
                //         .then((res) => {
                //             setQuotes(res.data);
                //         })
                //         .catch(error => {
                //             console.log(error)
                //         })
                // } else {
                //     alert("Unable to authenticate");
                // }
                fetch(`https://type.fit/api/quotes`, {
                        method: "GET"
                    })
                        .then(response => response.json())
                        .then((res) => {
                            setQuotes(res);
                            // console.log(res);
                        })
                        .catch(error => {
                            console.log(error)
                        })
            })
            .catch(error => {
                console.log(error)
            })
    }

    const getRandomQuote = () => {
        const quoteArrayA = quotes.map(o => o.text);
        const quoteArrayB = quotes.map(o => o.author);
        const randomItem = quoteArrayA[Math.floor(Math.random() * quoteArrayA.length)];
        setSingleQuote(randomItem);
        const a = quoteArrayA.indexOf(randomItem);
        let b = quoteArrayB[a];
        setAuthor(b);
    }

    //Translate Quote
    const transQuote = () => {
        const code = `${id}`;
        let fromLang = 'es';
        let toLang = code;
        let text = singleQuote;
        let url = `https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_API_KEY}`;
        url += '&q=' + encodeURI(text);
        url += `&source=${fromLang}`;
        url += `&target=${toLang}`;
        fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            }
        })
            .then(res => res.json())
            .then((response) => {
                const quotes = response.data.translations[0].translatedText;
                const replacedString = quotes.replace('&#39;', "'");
                setPhrase(replacedString);
            })
            .catch(error => {
                console.log("There was an error with the translation request: ", error);
            });
    }

    //Translate Author Name
    const transAuthor = () => {
        let fromLang = 'es';
        let toLang = `${id}`;
        let text = author;
        let url = `https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_API_KEY}`;
        url += '&q=' + encodeURI(text);
        url += `&source=${fromLang}`;
        url += `&target=${toLang}`;
        fetch(url, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            }
        })
            .then(res => res.json())
            .then((response) => {
                setAuth(response.data.translations[0].translatedText);

            })
            .catch(error => {
                console.log("There was an error with the translation request: ", error);
            });
    }

    //Next Quote Function
    function Next() {
        getRandomQuote();
        setPrevQuote(phrase);
        setPrevAuth(auth);
    }

    //Previous Quote Function
    function Prev() {
        setPhrase(prevQuote);
        setAuth(prevAuth);
    }

    return (
        <Container>
            <QuoteMenu />
            <div style={{ paddingTop: '20px' }}>
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={12} md={10} lg={10}>
                        <Grid className="ArticleCopy">
                            <blockquote>
                                {auth === 'null' || auth === 'निरर्थक' ? (
                                    <h3>{phrase}</h3>
                                ) : (
                                        <Grid>
                                            <h1>{phrase}</h1>
                                            <h3>- {auth}</h3>
                                        </Grid>
                                    )}
                                <Grid>
                                    {prevQuote !== null ? (
                                        <Grid container
                                            direction="row"
                                            justify="center"
                                            alignItems="center">
                                            <Button onClick={Prev} variant="outlined" size="small" style={{ color: '#000' }}><ArrowBackRoundedIcon style={{ fontSize: '16px' }} /></Button>
                                            <Button onClick={Next} variant="outlined" size="small" style={{ color: '#000' }}><ArrowForwardRoundedIcon style={{ fontSize: '16px' }} /></Button>
                                        </Grid>
                                    ) : (<Button onClick={Next} variant="outlined" size="small" style={{ color: '#000' }}><ArrowForwardRoundedIcon style={{ fontSize: '16px' }} /></Button>)}
                                </Grid>
                            </blockquote>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                    <Button href="https://play.google.com/store/apps/details?id=com.WorldWisdom" target="-blank">
                        <img src={require('../assets/playstore.png')} width="150" alt="" />
                    </Button>
                </Grid>
            </div>
        </Container>
    )
}


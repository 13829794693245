import React from 'react';
import Language from './components/Language';
import Quote from './components/Quote';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Error from './components/Error';

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/" component={Language} />
          <Route path="/quote/:id" component={Quote} />
          <Route component={Error} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

import { Container, Grid, Card, CardContent } from '@material-ui/core'
import React, { Component } from 'react'
import Menu from './Menu'
import { Link } from 'react-router-dom'
import '../index.css'


export default class Language extends Component {

    render() {

        return (
            <Container>
                <Menu />
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingTop: '30px' }}
                >

                    <h2>Choose Your Language</h2>
                </Grid>
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center" item xs={12} >
                    <Grid item sm={6} xs={6} sm={3} className="language">
                        <Card>
                            <CardContent>
                                <Link className="langLink" to={`/quote/en`}><h2>English</h2></Link>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6} sm={3} className="language">
                        <Card>
                            <CardContent>
                                <Link className="langLink" to={`/quote/hi`}> <h2>हिन्दी</h2></Link>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container
                    direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={6} sm={3} className="language" >
                        <Card>
                            <CardContent>
                                <Link className="langLink" to={`/quote/mr`}> <h2>मराठी</h2></Link>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6} sm={3} className="language" >
                        <Card>
                            <CardContent>
                                <Link className="langLink" to={`/quote/gu`}> <h2>ગુજરાતી</h2></Link>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        )
    }
}

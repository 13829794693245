import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Container } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
export default class QuoteMenu extends Component {
  render() {
    return (
      <Container style={{ paddingTop: '20px' }}>
        <div className="mobile-menu reverse"        >
          <Link to={`/`}> <img src={require('../assets/logo.png')} width="100" alt="" className="img" /> </Link>
          <Link to={`/`}>   <HomeIcon style={{ fontSize: 40, color: '#000' }} /> </Link>
        </div>
      </Container>
    )
  }
}
